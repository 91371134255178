import React from 'react'
import Banner from '../components/Home/Banner'
import Features from '../components/Home/Features'
import Setup from '../components/Home/Setup'
import PrintersIssues from '../components/Home/PrintersIssues'
import DoctorTool from '../components/Home/DoctorTool'
import ThreeFeatures from '../components/Home/ThreeFeatures'
// import Brands from '../components/Home/Brands'
import Testimonials from '../components/Home/Testimonials'
import Cards from '../components/Cards/Card'
import ModelCards from '../components/Home/ModelCards'

import { Helmet } from 'react-helmet';


function Home() {
  return (
    <div>
       <Helmet>
        <title>Expert Printer Solutions for Home & Business | GetPrinterHelp</title>
        <link rel="canonical" href="https://getprinterhelp.com/" />
        <meta name="description" content="Find expert solutions for all printer brands, including setup, troubleshooting, and driver issues. Get your printer online with easy, step-by-step guides from GetPrinterHelp." />
      </Helmet>
        <Banner/>
        {/* <Brands/> */}
        <Features/>
        <Cards/>
        <ModelCards/>
        <Setup/>
        <PrintersIssues/>
        <ThreeFeatures/>
        <DoctorTool/>
        <Testimonials/>
    </div>
  )
}

export default Home