import React, { useEffect } from "react";
import HpEnvyPrinter from "../../assests/HpEnvyPrinter.png";

function HpOfficejet() {
  useEffect(() => {
    document.title = "HP OfficeJet Printer Setup";
  }, []);
  return (
    <>
      <div className="bg-gradient-to-r from-blue-300 to-blue-700 -skew-y-6 ">
        <div className="flex flex-col mx-auto max-w-screen-xl gap-10 items-center justify-center md:flex-row pt-28 p-20">
          <div className="">
            <h1 className="text-2xl font-bold md:text-4xl lg:text-5xl">
              HP OfficeJet Printer
            </h1>
          </div>
          <div className="p-4 sm:p-10">
            <img className="" src={HpEnvyPrinter} alt="" />
          </div>
        </div>
      </div>

      {/* 2 section*/}
      <div className="mx-auto max-w-screen-xl mt-16 flex flex-col p-6">
        <h1 className="text-3xl font-semibold  text-center">
          A Quick Guide on Connecting HP Officejet Wireless Printer
        </h1>
        <p className="text-xl pt-4">
          In this present write up will aid you with the procedure to setup HP
          Officejet wireless printer on Windows, Mac and Smartphone device.
          First, we we will begin by explaining you ‘how to connect HP Officejet
          pro printer to Wireless network’.
        </p>
      </div>

      {/* HP Officejet Pro Setup to Wifi network */}
      <div className="mx-auto max-w-screen-xl mt-4 flex flex-col p-6">
        <h1 className="text-3xl font-semibold text-center">
          HP Officejet Pro Setup to Wifi network
        </h1>
        <p className="text-xl pt-4">
          To enable wireless printing, it is vital that you put your HP printer
          on the same network as your device (PC, Phone etc). Therefore, to
          connect HP Officejet printer to wireless router, follow these
          procedure steps:
        </p>
        <li className="list-disc text-xl pt-6 pl-6">
          Firstly, make sure that your router has WPS button. Then turn on your
          Officejet printer and Wifi router.
        </li>
        <li className="list-disc pl-6 text-xl">
          Then, go to the HP Officejet printer control panel settings from
          printer touch screen and open the “LAN Setup Wizard”. This will show
          you available WiFi network connections.
        </li>

        <li className="list-disc pl-6 text-xl">
          Select your WiFi connection where it will ask you for WPA security
          keyword (WiFi password).
        </li>
        <li className="list-disc pl-6 text-xl">
          Enter the security key and within few second your connection will be
          established with the HP Officejet printer.
        </li>
      </div>

      {/* Have a Look How to Setup Wireless Connection with Officejet Printer */}
      <div className="mx-auto max-w-screen-xl mt-4 flex flex-col p-6">
        <h1 className="text-3xl font-semibold">
          Have a Look How to Setup Wireless Connection with Officejet Printer
        </h1>
        <h1 className="text-3xl font-semibold pt-6 text-center">
          Connect HP Officejet Wireless Printer on Windows System
        </h1>
        <p className="text-xl pt-4">
          Here, look at the most effective way to connect HP OfficeJet Wireless
          printer to Windows.
        </p>
        <li className="list-disc text-xl pt-6 pl-6">
          Turn on the HP Officejet printer and connect it to the Wi-Fi router
          taking a cue from the first section of the write-up.
        </li>
        <li className="list-disc pl-6 text-xl">
          Quickly install the printer drivers and software on the computer that
          can be done in two ways. Either by using the installation cd that came
          with the printer or by going to the HP website (www.123.hp.com/setup)
          and download the driver from there directly.
        </li>
        <li className="list-disc text-xl pl-6">
          Visit the new window by opening the Control Panel and selecting the
          “Devices and Printers” option. On the new window click on the top
          right corner of the screen which shows the list of printers. Click on
          the “Add a printer” link.
        </li>
        <li className="list-disc text-xl pl-6">
          Once the new window opens, click “Next” on it.
        </li>
        <img
          className="mx-auto pt-4 pb-6"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/cwbe3no7axcdzfkyir7p.png"
          alt=""
        />

        <img
          className="mx-auto pt-4 pb-6"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/mqjwsmpfbpfh7m8rfmz2.png"
          alt=""
        />

        <img
          className="mx-auto pt-4 pb-6"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/wc2isbmkozj7rklwnege.png"
          alt=""
        />

        <li className="list-disc text-xl pl-6 pt-6">
          Here, you got to wait few second till your HP Officejet wireless
          printer is connected to the window.
        </li>
      </div>

      {/* Easiest and Most Effective Method to Connect HP Officejet Printer On Windows Using USB Cable */}
      <div className="mx-auto max-w-screen-xl mt-4 flex flex-col p-6">
        <h1 className="text-3xl font-semibold text-center">
          Easiest and Most Effective Method to Connect HP Officejet Printer On
          Windows Using USB Cable
        </h1>
        <p className="text-xl pt-4">
          Have a quick look at the procedural steps to connect HP Officejet
          printer on Windows using the USB cable.
        </p>

        <li className="list-disc text-xl pl-6 pt-6">
          First of all, visit the HP website and download the printer driver.
        </li>
        <li className="list-disc pl-6 text-xl">
          Select the “Devices and Printers” link from the Control Panel.
          Thereafter hit on “Printer and Scanner’ option.
        </li>
        <li className="list-disc pl-6 text-xl">
          Hit on the name of your printer from the given list after selecting
          the “Add new printer” option
        </li>
        <li className="list-disc text-xl pl-6">
          You have now successfully connected your HP Deskjet printer to USB
          cable.
        </li>
      </div>

      {/* Steps to Connect HP Officejet Wireless Printer on MAC */}
      <div className="mx-auto max-w-screen-xl mt-4 flex flex-col p-6">
        <h1 className="text-3xl font-semibold">
          Steps to Connect HP Officejet Wireless Printer on MAC
        </h1>
        <p className="text-xl pt-4">
          Have a look at the procedural steps to connect your HP Officejet
          wireless printer to the Mac system.
        </p>

        <li className="list-disc text-xl pl-6 pt-6">
          Ensure that both HP Officejet printer and system are connected to the
          same WiFi network.
        </li>
        <li className="list-disc text-xl pl-6">
          Now download and install the latest version of the printer driver by
          visiting the HP official website. Follow the on-screen instructions
          and finish the process of driver download.
        </li>
        <li className="list-disc text-xl pl-6">
          From the Apple menu select “System Preferences”.Thereafter click on
          the “Printers and Scanners” option from the new window.
        </li>
        <img
          className="mx-auto pt-4 pb-4"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/vrtaqmvwpf2wbr1tv3te.gif"
          alt=""
        />
        <img
          className="mx-auto pt-4 pb-4"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/qege6sucaobi1ufkbo7w.png"
          alt=""
        />
        <img
          className="mx-auto pt-4"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/l0xcuuptrki4fyplkiyw.png"
          alt=""
        />
        <li className="list-disc pt-6 pl-6 text-xl">
          Click on the “+” sign on the bottom left corner of the screen and add
          your HP Officejet printer.
        </li>
        <li className="list-disc pl-6 text-xl">
          Select your printer from the drop-down list and click on “Add” option.
        </li>
      </div>

      {/* Steps to Connect HP Officejet Wireless Printer on Smartphone */}
      <div className="mx-auto max-w-screen-xl mt-4 flex flex-col p-6">
        <h1 className="text-3xl font-semibold">
          Steps to Connect HP Officejet Wireless Printer on Smartphone
        </h1>
        <p className="text-xl pt-4">
          Here we have got for you the easiest and most effective way to connect
          HP OfficeJet wireless printer to the smartphone.
        </p>

        <li className="list-disc text-xl pl-6 pt-6">
          The android device should be connected to the same network as the
          printer and WiFi direct should be enabled on both phone and printer.
        </li>
        <li className="list-disc pl-6 text-xl">
          Then you need to install the HP print service driver app on the phone.
        </li>
        <li className="list-disc pl-6 text-xl">
          After finishing the installation process, choose your android device
          to turn on the plugin. For the Android 6, tap “Settings” and find open
          “HP print service” and hit on the “OK”. For the Android 7, the HP
          service plugin turns on automatically once the installation finished.
        </li>
        <li className="list-disc text-xl pl-6">
          Any non-required print service plugins should be turned off.
        </li>
        <li className="list-disc text-xl pl-6">
          Congratulations, you are now ready to print the page you wish to
          print. Tap the 3 vertical dot menu icon and tap on the “Print” option.
        </li>
        <li className="list-disc text-xl pl-6">
          Tap on the down arrow and view the printer list. To print over the
          network user needs to select their HP printer model name.
        </li>
        <li className="list-disc text-xl pl-6">
          Press on the print logo and you are good to go with your relevant
          printout!
        </li>
        <p className="font-bold text-xl pt-6">Conclusion</p>
        <p className="text-xl pt-4">
          The OfficeJet 4650 delivers more than it mentions on the packaging
          list. The prints look sharp although it is not of the same level as
          the prints produced by finer laser printers. HP offers an ink
          subscription plan. They call it Instant Ink service, that has three
          levels. The calculations are based on estimated page output. Once the
          printer is connected to the Internet, the company detects when the
          printer is low on ink. With all the HP Officejet Setup windows, HP
          Officejet eprint setup and HP Officejet Google Cloud print setup
          guides, operating this device seems to be very easy.
        </p>
        <p className="text-xl pt-6">
          The OfficeJet printer comes with some basic software that is used for
          printing, copying, scanning and fax. One can check it out from the
          home screen itself. One can also access forms and templates for
          printing and fax cover sheets, calendars, graph paper and such other
          tools. The HP Print Service application works well on Android phones.
          It facilitates to print a document with the print app, directly. There
          is so much to do with this sleek MFD. It is the best for your home or
          office. This is trending the market currently.
        </p>
      </div>
    </>
  );
}

export default HpOfficejet;
