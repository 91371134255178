import { Link } from 'react-router-dom'
import HpEnvy from '../../assests/modelsImages/HP Envy Printers.avif'
import { Helmet } from 'react-helmet'

function EnvyModel() {

    const items = [
        {
            id: 1,
            title: "HP Envy 4500",
        },
        {
            id: 2,
            title: "HP Envy 4502",
        },
        {
            id: 3,
            title: "HP Envy 4507",
        },
        {
            id: 4,
            title: "HP Envy 4510",
        },
        {
            id: 5,
            title: "HP Envy 4512",
        },
        {
            id: 6,
            title: "HP Envy 4520",
        },
        {
            id: 7,
            title: "HP Envy 5530",
        },
        {
            id: 8,
            title: "HP Envy 5535",
        },
        {
            id: 9,
            title: "HP Envy 5540",
        },
        {
            id: 10,
            title: "HP Envy 5544",
        },
        {
            id: 11,
            title: "HP Envy 5640",
        },
        {
            id: 12,
            title: "HP Envy 5642",
        },
        {
            id: 13,
            title: "HP Envy 5643",
        },
        {
            id: 14,
            title: "HP Envy 5660",
        },
        {
            id: 15,
            title: "HP Envy 7645",
        },
        {
            id: 16,
            title: "HP Envy 120",
        },
        {
            id: 17,
            title: "HP Envy 121",
        },
        {
            id: 18,
            title: "HP Envy 122",
        },
        {
            id: 19,
            title: "HP Envy 123",
        },
        {
            id: 20,
            title: "HP Envy 126",
        },
        {
            id: 21,
            title: "HP Envy 3835",
        },
        {
            id: 22,
            title: "HP Envy 4501",
        },
        {
            id: 23,
            title: "HP Envy 4503",
        },
        {
            id: 24,
            title: "HP Envy 4304",
        },
        {
            id: 25,
            title: "HP Envy 4505",
        },
        {
            id: 26,
            title: "HP Envy 4506",
        },
        {
            id: 27,
            title: "HP Envy 4511",
        },
        {
            id: 28,
            title: "HP Envy 4513",
        },
        {
            id: 29,
            title: "HP Envy 4514",
        },
        {
            id: 30,
            title: "HP Envy 4515",
        },
        {
            id: 31,
            title: "HP Envy 4516",
        },
        {
            id: 32,
            title: "HP Envy 4517",
        },
        {
            id: 33,
            title: "HP Envy 4518",
        },
        {
            id: 34,
            title: "HP Envy 4519",
        },
        {
            id: 35,
            title: "HP Envy 4523",
        },
        {
            id: 36,
            title: "HP Envy 4524",
        },
        {
            id: 37,
            title: "HP Envy 4525",
        },
        {
            id: 38,
            title: "HP Envy 4526",
        },
        {
            id: 39,
            title: "HP Envy 4527",
        },
        {
            id: 40,
            title: "HP Envy 4528",
        },
        {
            id: 41,
            title: "HP Envy 4529",
        },
        {
            id: 42,
            title: "HP Envy 5531",
        },
        {
            id: 43,
            title: "HP Envy 5532",
        },
        {
            id: 44,
            title: "HP Envy 5541",
        },
        {
            id: 45,
            title: "HP Envy 5542",
        },
        {
            id: 46,
            title: "HP Envy 5543",
        },
        {
            id: 47,
            title: "HP Envy 5545",
        },
        {
            id: 48,
            title: "HP Envy 5546",
        },
        {
            id: 49,
            title: "HP Envy 5547",
        },
        {
            id: 50,
            title: "HP Envy 5649",
        },
        {
            id: 51,
            title: "HP Envy 5662",
        },
        {
            id: 52,
            title: "HP Envy 5663",
        },
        {
            id: 53,
            title: "HP Envy 5664",
        },
        {
            id: 54,
            title: "HP Envy 5665",
        },
        {
            id: 55,
            title: "HP Envy 5666",
        },
        {
            id: 56,
            title: "HP Envy 5667",
        },
        {
            id: 57,
            title: "HP Envy 5668",
        },
        {
            id: 58,
            title: "HP Envy 5669",
        },
        {
            id: 59,
            title: "HP Envy 7641",
        },
        {
            id: 60,
            title: "HP Envy 7642",
        },
        {
            id: 61,
            title: "HP Envy 7643",
        },
        {
            id: 62,
            title: "HP Envy 7644",
        },
        {
            id: 63,
            title: "HP Envy 7646",
        },
        {
            id: 64,
            title: "HP Envy 7647",
        },
        {
            id: 65,
            title: "HP Envy 7648",
        },
        {
            id: 66,
            title: "HP Envy 7649",
        },
    ]


    return (
        <div>
            <Helmet>
            <link rel="canonical" href="https://getprinterhelp.com/envy-models" />
            </Helmet>

            <h1 className='text-black text-center p-10 bg-blue-500 mt-20 text-2xl font-bold md:text-3xl lg:text-4xl'>HP Envy Printer Setup and Install</h1>

            <div
                name=""
                className="bg-gradient-to-b from-gray-100 to-white w-full text-white md:h-full text-center md:text-left p-4"
            >
                <div className="max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full">
                    <div className="pb-8 mt-6">

                        <p className="text-black text-3xl font-bold  flex items-center justify-center pb-4 md:text-4xl">
                            Choose Your Envy Model
                        </p>
                    </div>

                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 sm:px-5 py-2">
                        {items.map(({ title, link, id }) => (
                            <div to={link} className="mt-8 sm:mt-12 border flex flex-col items-center shadow-lg shadow-gray-600 p-4 bg-white rounded-lg hover:scale-105 duration-500 mx-auto">
                                <img src={HpEnvy} alt="" className="w-[30%] h-[80%]" />
                                <h1 className='text-xl font-bold text-black mt-4 mb-4 text-center'>{title}</h1>
                                <p className="text-gray-600 text-center">Easy to Install and Setup Using Troubleshooting Guide</p>

                                <Link to="/knowmore" className='mt-4'>
                                    <button className='p-3 bg-slate-800 rounded-md hover:bg-slate-600 duration-200'>Know More</button>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnvyModel