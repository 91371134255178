import React, { useEffect } from "react";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";

function SomePolicy() {
  useEffect(() => {
    document.title = "Privacy Policy |  getprinterhelp";
  }, []);
  return (
    <div>
      <PrivacyPolicy />
    </div>
  );
}

export default SomePolicy;
