import React from 'react'
import DesktopBanner from '../../assests/desktop-banner.png'
import MobileBanner from '../../assests/mobile-banner.png'
import {Link} from 'react-router-dom'

function Banner() {
  return (
    <div className="font-bold cursor-pointer pt-20">
          <Link to={"/"}>
            {/* Show desktop logo for screens larger than or equal to medium (md) */}
            <img
              src={DesktopBanner}
              alt="Desktop Banner"
              className=" hidden md:block py-4"
            />

            {/* Show mobile logo for screens smaller than medium (md) */}
            <img
              src={MobileBanner}
              alt="Mobile Banner"
              className=" md:hidden"
            />
          </Link>
        </div>
  )
}

export default Banner