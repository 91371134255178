import React from "react";

function Description() {
  return (
    <div className="flex flex-col mx-auto max-w-screen-xl gap-4 p-4">
      <div className="">
        <p>
          We at “getprinterhelp” are setup experienced writers and authors who
          have set a benchmark in providing the information to the peoples from
          our extra-ordinary printer problems articles. We are best known in the
          technology where we have mention to troubleshoot all Kinds of Issues
          related to Printers.
        </p>
      </div>
      <div className="">
        <h3 className="pb-4 font-bold">Satisfactory Guide</h3>
        <p>
          We are not associated with any company or official brands and all the
          issues related to Ink Toner Blockage Issue, Driver Issues, Cartridge
          Issue, Printer paper Jam Issue, Spooler Issue and Connectivity Issues,
          all these information based on the various information platform and
          sources.
        </p>
      </div>
      <div className="">
        <h1 className="text-2xl pb-4">getprinterhelp Support Vision</h1>
        <div className="">
          <h2 className="font-bold text-2xl">Our Mission</h2>
          <p>
            Our mission is to provide highly reliable, innovative printing
            information and solution of your problems to all the person.{" "}
          </p>
        </div>
        <div className="pt-2">
          <h2 className="font-bold text-2xl">Our Vision</h2>
          <p>
            Our Vision is to let more and more readers enjoy our printing
            problems articles and post. So what are you waiting for? come to
            visit our website and find best articles and solution of your
            problems through our informative guide.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Description;
