
import Contact from '../components/ContactUs/ContactUs'
import Banner from '../components/ContactUs/Banner'
import { Helmet } from 'react-helmet'

function ContactUs() {

  return (
    <div>
      <Helmet>
        <title>Have Questions? Contact Us for Help | GetPrinterHelp</title>
        <link rel="canonical" href="https://getprinterhelp.com/contactus" />
        <meta name="description" content="Need assistance? Reach out to GetPrinterHelp with your printer issues. Shoot us an email and get expert solutions for all your printer-related queries." />
      </Helmet>
      <Banner/>
      <Contact/>
    </div>
  )
}

export default ContactUs