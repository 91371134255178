import {Link} from 'react-router-dom'
import HpLaser from '../../assests/modelsImages/HP LaserJet Printers.png'
import { Helmet } from 'react-helmet';

function HPLaserJet() {
      const items = [
    {
        id: 1,
        title: "HP Laserjet Printer 1000",
    },
    {
        id: 2,
        title: "HP Laserjet Printer 1320",
    },
    {
        id: 3,
        title: "HP Laserjet Printer 2000",
    },
    {
        id: 4,
        title: "HP Laserjet Printer 2430",
    },
    {
        id: 5,
        title: "HP Laserjet Printer 3000",
    },
    {
        id: 6,
        title: "HP Laserjet Printer 4000",
    },
    {
        id: 7,
        title: "HP Laserjet Printer 5000",
    },
    {
        id: 8,
        title: "HP Laserjet Printer 8000",
    },
    {
        id: 9,
        title: "HP Laserjet Printer 9000",
    },
    {
        id: 10,
        title: "HP Laserjet Printer cm1000",
    },
    {
        id: 11,
        title: "HP Laserjet Printer cm2000",
    },
    {
        id: 12,
        title: "HP Laserjet Printer cm3000",
    },
    {
        id: 13,
        title: "HP Laserjet Printer cm4000",
    },
    {
        id: 14,
        title: "HP Laserjet Printer cm6000",
    },
    {
        id: 15,
        title: "HP Laserjet Printer cm8000",
    },
    {
        id: 16,
        title: "HP Laserjet Printer cp1000",
    },
    {
        id: 17,
        title: "HP Laserjet Printer cp2000",
    },
    {
        id: 18,
        title: "HP Laserjet Printer cp3000",
    },
    {
        id: 19,
        title: "HP Laserjet Printer cp4000",
    },
    {
        id: 20,
        title: "HP Laserjet Printer cp5000",
    },
    {
        id: 21,
        title: "HP Laserjet Printer cp6000",
    },
    {
        id: 22,
        title: "HP Laserjet Printer m604n",
    },
    {
        id: 23,
        title: "HP Laserjet Printer m605n",
    },
    {
        id: 24,
        title: "HP Laserjet Printer m605x",
    },
    {
        id: 25,
        title: "HP Laserjet Printer p3015x",
    },
    {
        id: 26,
        title: "HP Laserjet Printer m100",
    },
    {
        id: 27,
        title: "HP Laserjet Printer m1000",
    },
    {
        id: 28,
        title: "HP Laserjet Printer m11",
    },
    {
        id: 29,
        title: "HP Laserjet Printer m200",
    },
    {
        id: 30,
        title: "HP Laserjet Printer m2000",
    },
    {
        id: 31,
        title: "HP Laserjet Printer m25",
    },
    {
        id: 32,
        title: "HP Laserjet Printer m300",
    },
    {
        id: 33,
        title: "HP Laserjet Printer m3000",
    },
    {
        id: 34,
        title: "HP Laserjet Printer m400",
    },
    {
        id: 35,
        title: "HP Laserjet Printer m4000",
    },
    {
        id: 36,
        title: "HP Laserjet Printer m500",
    },
    {
        id: 37,
        title: "HP Laserjet Printer m5000",
    },
    {
        id: 38,
        title: "HP Laserjet Printer m600",
    },
    {
        id: 39,
        title: "HP Laserjet Printer m700",
    },
    {
        id: 40,
        title: "HP Laserjet Printer m712dn",
    },
    {
        id: 41,
        title: "HP Laserjet Printer m800",
    },
    {
        id: 42,
        title: "HP Laserjet Printer m9000",
    },
    {
        id: 43,
        title: "HP Laserjet Printer p1000",
    },
    {
        id: 44,
        title: "HP Laserjet Printer p1505",
    },
    {
        id: 45,
        title: "HP Laserjet Printer p2000",
    },
    {
        id: 46,
        title: "HP Laserjet Printer p2025",
    },
    {
        id: 47,
        title: "HP Laserjet Printer p3000",
    },
    {
        id: 48,
        title: "HP Laserjet Printer p4000",
    },
    {
        id: 49,
        title: "HP Laserjet Printer Pro 102w",
    },
    {
        id: 50,
        title: "HP Laserjet Printer Pro M1536dnf",
    },
    {
        id: 51,
        title: "HP Laserjet Printer Pro 201dw",
    },
    {
        id: 52,
        title: "HP Laserjet Printer Pro M225dn",
    },
    {
        id: 53,
        title: "HP Laserjet Printer Pro M402dn",
    },
    {
        id: 54,
        title: "HP Laserjet Printer Pro M402dw",
    },
    {
        id: 55,
        title: "HP Laserjet Printer Pro M402n",
    },
    {
        id: 56,
        title: "HP Laserjet Printer Pro M426fdn",
    },
    {
        id: 57,
        title: "HP Laserjet Printer Pro M451dn",
    },
    {
        id: 58,
        title: "HP Laserjet Printer Pro M127fw",
    },
    {
        id: 59,
        title: "HP Laserjet Printer Pro P1102w",
    },
    {
        id: 60,
        title: "HP Laserjet Printer Pro P1606dn",
    },
    {
        id: 61,
        title: "HP Laserjet Printer Pro P2055dn",
    },
    {
        id: 62,
        title: "HP Laserjet Printer Pro 400 mfp m401n",
    },
    {
        id: 63,
        title: "HP Laserjet Printer Pro 400 mfp m425dn",
    },
    {
        id: 64,
        title: "HP Laserjet Printer Pro 400 mfp m401dne",
    },
    {
        id: 65,
        title: "HP Laserjet Printer Enterprise cm4540 mfp",
    },
    {
        id: 66,
        title: "HP Laserjet Printer Enterprise cm5525 mfp",
    },
    {
        id: 67,
        title: "HP Laserjet Printer Enterprise M552",
    },
    {
        id: 68,
        title: "HP Laserjet Printer Enterprise M553",
    },
    {
        id: 69,
        title: "HP Laserjet Printer Enterprise M553dn",
    },
    {
        id: 70,
        title: "HP Laserjet Printer Enterprise M553n",
    },
    {
        id: 71,
        title: "HP Laserjet Printer Enterprise M553x",
    },
    {
        id: 72,
        title: "HP Laserjet Printer Enterprise M651xh",
    },
    {
        id: 73,
        title: "HP Laserjet Printer Enterprise M750",
    },
    {
        id: 74,
        title: "HP Laserjet Printer Enterprise M855",
    },
    {
        id: 75,
        title: "HP Laserjet Printer Enterprise M880 Mfp",
    },
    {
        id: 76,
        title: "HP Laserjet Printer Enterprise M575c",
    },
    {
        id: 77,
        title: "HP Laserjet Printer Enterprise M577c",
    },
    {
        id: 78,
        title: "HP Laserjet Printer Enterprise M577dn",
    },
    {
        id: 79,
        title: "HP Laserjet Printer Enterprise M577f",
    },
    {
        id: 80,
        title: "HP Laserjet Printer Enterprise M680dn",
    },
    {
        id: 81,
        title: "HP Laserjet Printer Enterprise M680f",
    },
    {
        id: 82,
        title: "HP Laserjet Printer Managed M553xm",
    },
    {
        id: 83,
        title: "HP Laserjet Printer Managed M680zm",
    },
    {
        id: 84,
        title: "HP Laserjet Printer Managed M775fm",
    },
    {
        id: 85,
        title: "HP Laserjet Printer Managed M775zm",
    },
    {
        id: 86,
        title: "HP Laserjet Printer Managed M880zm",
    },
    {
        id: 87,
        title: "HP Laserjet Printer Pro 2500",
    },
    {
        id: 88,
        title: "HP Laserjet Printer Pro 4600dtn",
    },
    {
        id: 89,
        title: "HP Laserjet Printer Pro cp1025nw",
    },
    {
        id: 90,
        title: "HP Laserjet Printer cp1525nw",
    },
    {
        id: 91,
        title: "HP Laserjet Printer Pro 2025dn",
    },
    {
        id: 92,
        title: "HP Laserjet Printer Pro cp4025dn",
    },
    {
        id: 93,
        title: "HP Laserjet Printer Pro cp4545dn",
    },
    {
        id: 94,
        title: "HP Laserjet Printer Pro cp5225dn",
    },
    {
        id: 95,
        title: "HP Laserjet Printer Pro cp5525",
    },
    {
        id: 96,
        title: "HP Laserjet Printer Pro M252dn",
    },
    {
        id: 97,
        title: "HP Laserjet Printer Pro M252",
    },
    {
        id: 98,
        title: "HP Laserjet Printer Pro M252dw",
    },
    {
        id: 99,
        title: "HP Laserjet Printer Pro 300 M351a",
    },
    {
        id: 100,
        title: "HP Laserjet Printer Pro 400 M451",
    },
    {
        id: 101,
        title: "HP Laserjet Printer Pro M452dn",
    },
    {
        id: 102,
        title: "HP Laserjet Printer Pro M452dw",
    },
    {
        id: 103,
        title: "HP Laserjet Printer Pro M452nw",
    },
    {
        id: 104,
        title: "HP Laserjet Printer Pro M477fdw",
    },
    {
        id: 105,
        title: "HP Laserjet Printer Pro 500 M551",
    },
    {
        id: 106,
        title: "HP Laserjet Printer Pro mfp cp5225",
    },
    {
        id: 107,
        title: "HP Laserjet Printer Pro 100mfp M175",
    },
    {
        id: 108,
        title: "HP Laserjet Printer Pro 100mfp M175a",
    },
    {
        id: 109,
        title: "HP Laserjet Printer Pro 100mfp M175nw",
    },
    {
        id: 110,
        title: "HP Laserjet Printer Pro 100mfp M177fw",
    },
    {
        id: 111,
        title: "HP Laserjet Printer Pro mfp M274n",
    },
    {
        id: 112,
        title: "HP Laserjet Printer Pro 200mfp M276nw",
    },
    {
        id: 113,
        title: "HP Laserjet Printer Pro mfp M277",
    },
    {
        id: 114,
        title: "HP Laserjet Printer Pro mfp 277dw",
    },
    {
        id: 115,
        title: "HP Laserjet Printer Pro 300mfp M351a",
    },
    {
        id: 116,
        title: "HP Laserjet Printer Pro 300mfp M375nw",
    },
    {
        id: 117,
        title: "HP Laserjet Printer Pro 400mfp M475dn",
    },
    {
        id: 118,
        title: "HP Laserjet Printer Pro mfp M475dw",
    },
    {
        id: 119,
        title: "HP Laserjet Printer Pro mfp M477fdn",
    },
    {
        id: 120,
        title: "HP Laserjet Printer Pro mfp M477fdw",
    },
    {
        id: 121,
        title: "HP Laserjet Printer Pro mfp M477fnw",
    },
    {
        id: 122,
        title: "HP Laserjet Printer Pro 500mfp M570dn",
    },
    {
        id: 123,
        title: "HP Laserjet Printer Pro 500mfp M575",
    },
    {
        id: 124,
        title: "HP Laserjet Printer Pro 700mfp M775",
    },
    {
        id: 125,
        title: "HP Laserjet Printer Pro 700mfp M775dn",
    },
    {
        id: 126,
        title: "HP Laserjet Printer Pro 700mfp M775z",
    },
    {
        id: 127,
        title: "HP Laserjet Printer Pro mfp M476nw",
    },
    {
        id: 128,
        title: "HP Laserjet Printer Pro mfp M476dw",
    },
    {
        id: 129,
        title: "HP Laserjet Printer Enterprise M506dn",
    },
];
    
  return (
    <div>
         <Helmet>
         <link rel="canonical" href="https://www.getprinterhelp.com/hp-laserjet-printer" />
            </Helmet>

            <h1 className='text-black text-center p-10 bg-blue-500 mt-20 text-2xl font-bold md:text-3xl lg:text-4xl'>HP Laserjet Printer Setup and Install

</h1>

            <div
                name=""
                className="bg-gradient-to-b from-gray-100 to-white w-full text-white md:h-full text-center md:text-left p-4"
            >
                <div className="max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full">
                    <div className="pb-8 mt-6">
                        
                        <p className="text-black text-3xl font-bold  flex items-center justify-center pb-4 md:text-4xl">
                        Choose your Model
                        </p>
                    </div>

                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 sm:px-5 py-2">
                        {items.map(({title,link }) => (
                            <div to={link} className="mt-8 sm:mt-12 border flex flex-col items-center shadow-lg shadow-gray-600 p-4 bg-white rounded-lg hover:scale-105 duration-500 mx-auto">
                                <img src={HpLaser} alt="" className="w-[30%] h-[80%]" />
                                <h1 className='text-xl font-bold text-black mt-4 mb-4 text-center'>{title}</h1>
                                <p className="text-gray-600 text-center">Printer Installation and Setup, Troubleshoot Problem</p>

                                <Link to="/knowmore-hp-laserjet-printer" className='mt-4'>
                                    <button className='p-3 bg-slate-800 rounded-md hover:bg-slate-600 duration-200'>Know More</button>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div> 
  )
}

export default HPLaserJet