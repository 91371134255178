import React from "react";
import { Link } from "react-router-dom";

function Banner() {
  return (
    <section className="bg-gray-50 md:mt-10">
      <div className="mx-auto w-full px-4 py-32  lg:flex lg:h-full lg:items-center">
        <div className="mx-auto max-w-3xl text-center">
          <h1 className="text-3xl font-extrabold sm:text-5xl">
            Your Troubleshooting Guide.
            <strong className="font-extrabold text-blue-800 sm:block">
              {" "}
              Solving Printer Problems Made Easy!{" "}
            </strong>
          </h1>

          <p className="mt-4 sm:text-xl/relaxed">
            Expert Solutions and Tips to Resolve Any Printer Issue Quickly and
            Effectively, Making Printing Hassle-Free for You!
          </p>

          <div className="mt-8 flex flex-wrap justify-center gap-4">
            <Link
              to={"/"}
              className="block w-full rounded bg-blue-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-blue-700 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
            >
              Get Started
            </Link>

            <Link
              to={"/contactus"}
              className="block w-full rounded px-12 py-3 text-sm font-medium text-blue-600 shadow hover:text-blue-700 focus:outline-none focus:ring active:text-blue-500 sm:w-auto"
            >
              Let&apos;s Talk
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
