import React from 'react'

function Banner() {
  return (
    <div className='mt-20 p-20 bg-gradient-to-t from-gray-200 to-white'>
        <h1 className='text-3xl font-extrabold text-center md:text-5xl text-blue-800'>Contact us</h1>
    </div>
  )
}

export default Banner