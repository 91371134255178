import React, { useEffect } from "react";
import HpEnvyPrinter from "../../assests/HpEnvyPrinter.png";

function HpEnvy() {
  useEffect(() => {
    document.title = "HP Envy Printer Setup";
  }, []);
  return (
    <>
      <div className="bg-gradient-to-r from-blue-300 to-blue-700 -skew-y-6 ">
        <div className="flex flex-col mx-auto max-w-screen-xl gap-10 items-center justify-center md:flex-row pt-28 p-20">
          <div className="">
            <h1 className="text-2xl font-bold md:text-4xl lg:text-5xl">
              HP Envy Printer Setup
            </h1>
          </div>
          <div className="p-4 sm:p-10">
            <img className="" src={HpEnvyPrinter} alt="" />
          </div>
        </div>
      </div>

      {/* 2 section*/}
      <div className="mx-auto max-w-screen-xl mt-16 flex flex-col p-6">
        <h1 className="text-3xl font-semibold">
          HP Envy Printer Wireless Setup on Windows and MAC
        </h1>
        <p className="text-xl pt-4">
          The present writeup has been penned down to answer the various queries
          we have been lately receiving pertaining to the HP Envy printer’s
          wireless setup on Windows and Mac.
        </p>
      </div>

      {/* How To Connect HP Envy Printer To The WiFi Router */}
      <div className="mx-auto max-w-screen-xl mt-4 flex flex-col p-6">
        <h1 className="text-3xl font-semibold">
          How To Connect HP Envy Printer To The WiFi Router
        </h1>
        <li className="list-disc text-xl pt-6 pl-6">
          Turn on the printer and WiFi router. Ensure that router has the WPS
          button.
        </li>
        <li className="list-disc pl-6 text-xl">
          Go to the touchscreen of the printer and open the LAN setup wizard.
          This will then start finding the WiFi router.
        </li>

        <li className="list-disc pl-6 text-xl">
          Now the user needs to select the WiFi router which will then ask for
          the WPA key ( i.e the password)
        </li>
        <li className="list-disc pl-6 text-xl">
          Now within few seconds your printer will be connected to your WiFi
          router.
        </li>
      </div>

      {/* Steps to Connect HP Envy Wireless Printer on Windows */}
      <div className="mx-auto max-w-screen-xl mt-4 flex flex-col p-6">
        <h1 className="text-3xl font-semibold">
          Steps to Connect HP Envy Wireless Printer on Windows
        </h1>
        <p className="text-xl pt-4">
          Here is the easiest and most reliable way through which you can
          connect HP Envy wireless printer to Windows.
        </p>
        <li className="list-disc text-xl pt-6 pl-6">
          First of all, the user must ensure that printer is turned on and
          connected to the WiFi router as explained in detail in the above
          section.{" "}
        </li>
        <li className="list-disc pl-6 text-xl">
          Now, the user needs to install printer driver and software on the
          computer. This can be done in two ways. One by using the installation
          cd that came along with the printer or by going to the HP website
          (www.123.hp.com/setup) and downloading the driver from there directly.
        </li>
        <li className="list-disc text-xl pl-6">
          Then, visit the Control Panel and select “Devices and Printers”
          option. This will then take you to a new window, click on the top
          right corner “Add a printer” link
        </li>
        <img
          className="mx-auto pt-4 pb-6"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/cwbe3no7axcdzfkyir7p.png"
          alt=""
        />

        <img
          className="mx-auto pt-4 pb-6"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/mqjwsmpfbpfh7m8rfmz2.png"
          alt=""
        />

        <img
          className="mx-auto pt-4 pb-6"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/wc2isbmkozj7rklwnege.png"
          alt=""
        />

        <li className="list-disc text-xl pl-6 pt-6">
          Next, select your printer model from the screen which shows the list
          of printers.
        </li>
        <li className="list-disc text-xl pl-6">
          Click the ‘Next” option on the new window. Wait patiently till your HP
          Envy printer is connected to the window.
        </li>
      </div>

      {/* That’s How You Can Connect HP Envy Printer on Windows By Using USB Cable */}
      <div className="mx-auto max-w-screen-xl mt-4 flex flex-col p-6">
        <h1 className="text-3xl font-semibold">
          That’s How You Can Connect HP Envy Printer on Windows By Using USB
          Cable
        </h1>
        <p className="text-xl pt-4">
          Here’s how you can easily set up your Hp Envy wireless printer to
          Windows via USB cable.
        </p>

        <li className="list-disc text-xl pl-6 pt-6">
          As an obvious step, the user needs to have a USB cable for this setup.
          Connect one end of the USB cable to your HP Envy wireless printer and
          the other end to the computer.
        </li>
        <img
          className="mx-auto pt-4"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/xmhd6wr3bbftjlufv8ot.gif"
          alt=""
        />
        <li className="list-disc pt-6 pl-6 text-xl">
          Now download the printer driver from the HP official website.{" "}
        </li>
        <li className="list-disc pl-6 text-xl">
          Here go to the Control Panel and click on “Devices and printers”.
        </li>
        <li className="list-disc text-xl pl-6">
          Hit on “Add new printer” option and select the name of your printer
          from the given list.
        </li>
        <p className="text-xl pt-4">
          You have now successfully connected your HP Envy printer on Windows
          using the USB cable
        </p>
      </div>

      {/* Thats How You Can Connect The HP Envy Wireless Printer To The Mac System */}
      <div className="mx-auto max-w-screen-xl mt-4 flex flex-col p-6">
        <h1 className="text-3xl font-semibold">
          Thats How You Can Connect The HP Envy Wireless Printer To The Mac
          System
        </h1>
        <p className="text-xl pt-4">
          Here’s how you can easily connect your HP Envy wireless printer to the
          Mac system.
        </p>

        <li className="list-disc text-xl pl-6 pt-6">
          Turn on the printer and the WiFi router. Ensure that both printer and
          system are connected to the same network.
        </li>
        <li className="list-disc text-xl pl-6">
          Begin by connecting your HP envy printer to the WiFi network as
          mentioned in the first section of the writeup.
        </li>
        <li className="list-disc text-xl pl-6">
          Now, download and install the latest version of the printer driver by
          visiting HP website. Follow the on-screen instructions and finish the
          process of driver download.
        </li>
        <li className="list-disc text-xl pl-6">
          Go to the “Apple” menu and select ‘System Preferences”. Thereafter
          click on the “Printers and Scanners” option on the new window that
          appears on the screen.
        </li>
        <img
          className="mx-auto pt-4"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/vrtaqmvwpf2wbr1tv3te.gif"
          alt=""
        />
        <img
          className="mx-auto pt-4"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/qege6sucaobi1ufkbo7w.png"
          alt=""
        />
        <li className="list-disc pt-6 pl-6 text-xl">
          Click on the “+” sign located on the bottom left corner of the screen.
          This will add your HP Envy printer.
        </li>
        <img
          className="mx-auto pt-4"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/l0xcuuptrki4fyplkiyw.png"
          alt=""
        />
        <li className="list-disc pl-6 text-xl pt-6">
          Finally, on the top left side of the screen, you will see your HP Envy
          printer connected to the Mac system.
        </li>
      </div>

      {/* Connect HP Envy Wireless Printer To Smartphone */}
      <div className="mx-auto max-w-screen-xl mt-4 flex flex-col p-6">
        <h1 className="text-3xl font-semibold">
          Connect HP Envy Wireless Printer To Smartphone
        </h1>
        <p className="text-xl pt-4">
          Here is a quick and easy way that would help you to connect HP Envy
          printer to the smartphone. So printing via your smartphone is not a
          tough job now.
        </p>

        <li className="list-disc text-xl pl-6 pt-6">
          Before starting the procedure, the user needs to ensure that their
          Android device is connected to the same network as the printer and
          WiFi direct is enabled on both phone and printer.
        </li>
        <li className="list-disc pl-6 text-xl">
          Install the HP print service driver app on the phone.{" "}
        </li>
        <li className="list-disc pl-6 text-xl">
          After the installation procedure finishes, the user needs to choose
          their android device to turn on the plugin. For Android 6 and the
          earlier devices, tap “Settings” and search for and open “HP print
          service” and click on “OK”. In the case of Android 7, the HP service
          plugin turns on automatically once the installation completes.
        </li>
        <li className="list-disc text-xl pl-6">
          Here at this step turn off any non required print service plugins.
        </li>
        <li className="list-disc text-xl pl-6">
          Now you are ready to print the page you wish to print. Tap the three
          vertical dot menu icon and hit the “Print” option. Thereafter a print
          preview screen will appear.
        </li>
        <li className="list-disc text-xl pl-6">
          Tap on the down arrow and view the printer list. To print over the
          network user needs to select their HP printer model name.
        </li>
        <li className="list-disc text-xl pl-6">
          Tap on the print icon and you are good to go with your desired
          printout!
        </li>
        <p className="text-xl pt-4">
          <b>Final Words – </b>The above describe steps will guide you on how to
          connect HP envy wireless setup through router and connect to Windows
          and MAC system.{" "}
        </p>
      </div>
    </>
  );
}

export default HpEnvy;
