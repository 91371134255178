import React from 'react'
import PrinterIssues from '../../assests/printerIssue.gif'

function PrintersIssues() {
  return (
    <section className="pt-20 md:pt-40 md:ml-4 lg:ml-20">
    <div className="container mx-auto px-8 lg:flex gap-10">
    <div className="text-start lg:text-left lg:w-1/2">
            <h1 className="text-2xl xl:text-3xl font-bold border-b-4 border-blue-700">Here are some alternate points to consider when troubleshooting a printer offline issue:</h1>
            <p className="text-lg mt-6 font-light">Restart Devices: Sometimes, a simple restart can resolve the offline problem. Turn off both your printer and computer, then turn them back on after a minute or two.</p>

            <ol className="mt-4 text-gray-600">
            <li>1. Check Network Connection: Ensure that your printer is properly connected to the Wi-Fi network. Check the Wi-Fi settings on your printer to verify the connection.</li>
    <li>2. Update Firmware: Check if there are any firmware updates available for your printer. Updating the firmware can sometimes resolve connectivity issues.</li>
    <li>3. Check Printer Status: Look for any error messages or indicators on the printer's display panel. This might provide clues about the cause of the offline issue.</li>
    <li>4. Reset Printer: Try resetting the printer to its factory defaults. Refer to the printer's manual for instructions on how to perform a reset.</li>
    <li>5. Disable Firewall/Antivirus: Sometimes, firewall or antivirus software can block the communication between the printer and computer. Temporarily disable these software and check if the printer comes online.</li>
    <li>6. Check Printer Settings: Verify that the printer is set as the default printer on your computer. Sometimes, a change in settings can cause the printer to go offline.</li>
    <li>7. Reinstall Printer Software: Uninstall the printer software from your computer and then reinstall it. This can help refresh the printer settings and drivers.</li>
    <li>8. Printer setup,Install printer,Set up printer,Printer software,Driver for printer,Connect printer,Add printer,Printer driver setup.</li>
</ol>
        </div>
        <div className="lg:w-1/2 pt-4 lg:ml-10 lg:pt-32">
        <img loading="lazy" src={PrinterIssues} alt="" />
            </div>
    </div>
</section>
  )
}

export default PrintersIssues