import React, { useState } from "react";
import { db } from "../../config/firebase";
import { collection, addDoc } from "firebase/firestore";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, mobile } = formData;

    if (name.trim() === "" || email.trim() === "" || mobile.trim() === "") {
      alert("All fields are required!");
      return;
    }

    const timestamp = new Date();
    try {
      await addDoc(collection(db, "contacts"), {
        name: name.trim(),
        email: email.trim(),
        mobile: mobile.trim(),
        timestamp,
      });
      alert("Form submitted successfully!");
      setFormData({
        name: "",
        email: "",
        mobile: "",
      });
    } catch (error) {
      console.error("Error adding document: ", error);
      alert(
        "Error submitting form. Please try again later. Error details: " +
          error.message
      );
    }
  };

  return (
    <div>
      <div className="mx-auto max-w-7xl px-4">
        <div className="mx-auto max-w-7xl py-12 md:py-24">
          <div className="grid items-center justify-items-center gap-x-4 gap-y-10 lg:grid-cols-2">
            {/* contact form */}
            <div className="flex items-center justify-center">
              <div className="px-2 md:px-12">
                <p className="text-2xl font-bold text-gray-900 md:text-4xl">
                  Have Queries, Need Help? Contact Us
                </p>
                <p className="mt-4 text-lg text-gray-600">Shoot us an Email.</p>
                <form onSubmit={handleSubmit} className="mt-8 space-y-4">
                  <div className="grid w-full items-center gap-1.5">
                    <label
                      className="text-sm font-medium leading-none text-gray-700 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      htmlFor="name"
                    >
                      Name
                    </label>
                    <input
                      className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                      type="text"
                      id="name"
                      required
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="grid w-full items-center gap-1.5">
                    <label
                      className="text-sm font-medium leading-none text-gray-700 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50 "
                      type="email"
                      id="email"
                      placeholder="Email"
                      required
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="grid w-full items-center gap-1.5">
                    <label
                      className="text-sm font-medium leading-none text-gray-700 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      htmlFor="mobile"
                    >
                      Mobile
                    </label>
                    <input
                      className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                      type="tel"
                      id="mobile"
                      required
                      placeholder="Mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                    />
                  </div>

                  <button
                    type="submit"
                    className="inline-flex items-center justify-center py-3 text-base font-medium text-center bg-blue-600 text-white border border-transparent rounded-md px-7 bg-primary hover:bg-green-500 w-full"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>

            {/* contact details */}
            <div className="">
              <h1 className="text-2xl font-bold mb-2">Contact Us</h1>
              <div className="">
                {/* <p>
                  <b>Email :</b>info@ezyprintersupport.com
                </p> */}
                <img
                  src="https://res.cloudinary.com/dby6tugob/image/upload/v1727332326/yrgkhdlujxir2qtveg8c.webp"
                  alt="contact us"
                  className="mt-6"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="" />
    </div>
  );
}
