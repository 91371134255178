import React from "react";

function PrivacyPolicy() {
  return (
    <div className="flex flex-col gap-4 p-4 mt-20 md:mt-32 mx-auto max-w-screen-xl ">
      <h1 className="font-bold text-3xl">Privacy Policy</h1>
      <div>
        <h3 className="p-4">
          We “getprinterhelp” DO NOT hold a commitment to protect the details/
          privacy of the readers. If you are visiting our website to take a
          sneak peek or to know more about our troubleshooting guide, we do not
          ask for your personal information.
        </h3>
      </div>
      <div className="flex flex-col gap-4">
        <div className="">
          <h2 className="text-2xl font-semibold mb-4">
            What Is The Information We Ask For?
          </h2>
          <p>
            When you fill out the form to initiate chat, or when you hit up our
            subscriber list for the latest news, blog or other updates of our
            company, then we ask for your details such as:
          </p>
          <ul className="list-disc p-12">
            <li>Name</li>
            <li>Email- Address</li>
            <li>Contact Number</li>
          </ul>
          <p>
            These details are then forwarded to one of the manufacture
            companies.
          </p>
        </div>

        <div className="">
          <h2 className="text-2xl font-semibold mb-4">
            How Measures We Take To Protect Our Customer&apos;s Privacy?
          </h2>
          <p>
            Our company’s website uses <b>SSL (secure socket layer)</b> to
            protect all your personal details. We never ever share our readers
            details to any of the independent company or any outsider
            individual. Protecting your details is our main consideration.
          </p>
        </div>

        <div className="flex flex-col gap-4 p-4 mt-20 mx-auto max-w-screen-xl ">
          <h2 className="text-2xl font-semibold mb-4">
            Why Do We Collect Our Customer&apos;s Information?
          </h2>
          <p>
            Your personal information collected by us is used to locate/ contact
            you. It is also used in the case to notify about our exciting
            offers/ gifts programs so that you could take advantage of it. If
            the information we have collected from you contains or is linked
            with your other information, it will become automatically your
            private information.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
