import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home";
import Admin from "./pages/admin/Admin";
import NoPage from "./pages/NoPage";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import SomePolicy from "./pages/SomePolicy";
// Cards
import NewPrinterSetup from "./components/CardPages/NewPrinterSetup";
import PrinterTroubleshooting from "./components/CardPages/PrinterTroubleshooting";
import PrinterDriverInstallation from "./components/CardPages/PrinterDriverInstallation";
import ConnectPrinterToWifi from "./components/CardPages/ConnectPrinterToWifi";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import WirelessPrinterSetup from "./components/CardPages/WirelessPrinterSetup";
import HpPrinterOffline from "./components/CardPages/HpPrinterOffline";
import DiagnosePrinterProblem from "./components/CardPages/DiagnosePrinterProblem";
import PrinterColorPrintingError from "./components/CardPages/PrinterColorPrintingError";

// HP Models Routes
import EnvyModel from "./components/HPModels/EnvyModel";
import HPOfficeJetPro from "./components/HPModels/HPOfficeJetPro";
import HPDeskJet from "./components/HPModels/HPDeskJet";
import HPLaserJet from "./components/HPModels/HPLaserJet";

// Know more routes
import HpEnvyKnowMore from "./components/KnowMore/HpEnvyKnowMore";
import HpOfficeJetKnowMore from "./components/KnowMore/HpOfficeJetKnowMore";
import HpDeskJetKnowMore from "./components/KnowMore/HpDeskJetKnowMore";
import HpLaserJetKnowMore from "./components/KnowMore/HpLaserJetKnowMore";

// Some more setup Routes
import HpEnvy from "./components/SetUpRoutes/HpEnvy";
import HpOfficejet from "./components/SetUpRoutes/HpOfficejet";
import HpDeskJetInk from "./components/SetUpRoutes/HpDeskJetInk";
import HpLaserJetSetup from "./components/SetUpRoutes/HpLaserJetSetup";

function Layout({ children }) {
  const location = useLocation();
  const hideNavbarAndFooter = location.pathname === "/admin";

  return (
    <>
      {!hideNavbarAndFooter && <Navbar />}
      {children}
      {!hideNavbarAndFooter && <Footer />}
    </>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <Layout>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<SomePolicy/>}/>
          
          {/* Cards routes */}
          <Route path="/new-printer-setup" element={<NewPrinterSetup/>}/>
          <Route path="/hp-printer-troubleshooting-guide" element={<PrinterTroubleshooting/>}/>
          <Route path="/how-to-install-hp-printer-driver" element={<PrinterDriverInstallation/>}/>
          <Route path="/connect-canon-printer-to-wifi" element={<ConnectPrinterToWifi/>}/>
          <Route path="/setup-hp-printer" element={<WirelessPrinterSetup/>}/>
          <Route path="/hp-printer-is-offline" element={<HpPrinterOffline/>}/>
          <Route path="/guide-to-diagnose-hp-printer-problems" element={<DiagnosePrinterProblem/>}/>
          <Route path="/hp-printer-color-not-printing" element={<PrinterColorPrintingError/>}/>

          {/* HP Models Routes */}
          <Route path="/envy-models" element={<EnvyModel/>}/>
          <Route path="/hp-officejet-pro-printer" element={<HPOfficeJetPro/>}/>
          <Route path="/hp-deskjet-ink" element={<HPDeskJet/>}/>
          <Route path="/hp-laserjet-printer" element={<HPLaserJet/>}/>

          {/* Some more setup routes */}
          <Route path="/hp-envy-printer-setup" element={<HpEnvy/>}/>
          <Route path="/hp-officejet-printer-pro-setup" element={<HpOfficejet/>}/>
          <Route path="/hp-deskjet-printer-setup" element={<HpDeskJetInk/>}/>
          <Route path="/hp-laserjet-printer-setup" element={<HpLaserJetSetup/>}/>

          {/* Know more */}
          <Route path="/knowmore" element={<HpEnvyKnowMore/>}/>
          <Route path="/knowmore-officejet" element={<HpOfficeJetKnowMore/>}/>
          <Route path="/knowmore-hp-deskjet-printer" element={<HpDeskJetKnowMore/>}/>
          <Route path="/knowmore-hp-laserjet-printer" element={<HpLaserJetKnowMore/>}/>


          <Route path="/admin" element={<Admin />} />
          <Route path="/*" element={<NoPage />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}
