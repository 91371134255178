import React,{useEffect} from 'react'
import Banner from '../components/About/Banner'
import Description from '../components/About/Description'

function About() {
  useEffect(() => {
    document.title = 'About Us - getprinterhelp';
  }, []);
  return (
    <div>
        <Banner/>
        <Description/>
    </div>
  )
}

export default About