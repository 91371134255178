import {Link} from 'react-router-dom'
import HPDeskject from '../../assests/modelsImages/HP DeskJet Ink Printers.webp'
import { Helmet } from 'react-helmet';

function HPDeskJet() {
    const items = [
    {
        id: 1,
        title: "HP DeskJet Ink 2130",
    },
    {
        id: 2,
        title: "HP DeskJet Ink 2510",
    },
    {
        id: 3,
        title: "HP DeskJet Ink 2540",
    },
    {
        id: 4,
        title: "HP DeskJet Ink 3510",
    },
    {
        id: 5,
        title: "HP DeskJet Ink 3520",
    },
    {
        id: 6,
        title: "HP DeskJet Ink 5810",
    },
    {
        id: 7,
        title: "HP DeskJet Ink 5820",
    },
    {
        id: 8,
        title: "HP DeskJet Ink 1011",
    },
    {
        id: 9,
        title: "HP DeskJet Ink 1012",
    },
    {
        id: 10,
        title: "HP DeskJet Ink 1013",
    },
    {
        id: 11,
        title: "HP DeskJet Ink 1014",
    },
    {
        id: 12,
        title: "HP DeskJet Ink 1015",
    },
    {
        id: 13,
        title: "HP DeskJet Ink 1016",
    },
    {
        id: 14,
        title: "HP DeskJet Ink 1017",
    },
    {
        id: 15,
        title: "HP DeskJet Ink 1018",
    },
    {
        id: 16,
        title: "HP DeskJet Ink 1019",
    },
    {
        id: 17,
        title: "HP DeskJet Ink 1110",
    },
    {
        id: 18,
        title: "HP DeskJet Ink 1111",
    },
    {
        id: 19,
        title: "HP DeskJet Ink 1113",
    },
    {
        id: 20,
        title: "HP DeskJet Ink 1114",
    },
    {
        id: 21,
        title: "HP DeskJet Ink 1115",
    },
    {
        id: 22,
        title: "HP DeskJet Ink 1116",
    },
    {
        id: 23,
        title: "HP DeskJet Ink 1117",
    },
    {
        id: 24,
        title: "HP DeskJet Ink 1118",
    },
    {
        id: 25,
        title: "HP DeskJet Ink 1119",
    },
    {
        id: 26,
        title: "HP DeskJet Ink 1511",
    },
    {
        id: 27,
        title: "HP DeskJet Ink 1512",
    },
    {
        id: 28,
        title: "HP DeskJet Ink 1513",
    },
    {
        id: 29,
        title: "HP DeskJet Ink 1514",
    },
    {
        id: 30,
        title: "HP DeskJet Ink 1515",
    },
    {
        id: 31,
        title: "HP DeskJet Ink 1516",
    },
    {
        id: 32,
        title: "HP DeskJet Ink 1517",
    },
    {
        id: 33,
        title: "HP DeskJet Ink 2131",
    },
    {
        id: 34,
        title: "HP DeskJet Ink 2132",
    },
    {
        id: 35,
        title: "HP DeskJet Ink 1518",
    },
    {
        id: 36,
        title: "HP DeskJet Ink 1519",
    },
    {
        id: 37,
        title: "HP DeskJet Ink 2133",
    },
    {
        id: 38,
        title: "HP DeskJet Ink 2134",
    },
    {
        id: 39,
        title: "HP DeskJet Ink 2135",
    },
    {
        id: 40,
        title: "HP DeskJet Ink 2136",
    },
    {
        id: 41,
        title: "HP DeskJet Ink 2137",
    },
    {
        id: 42,
        title: "HP DeskJet Ink 2138",
    },
    {
        id: 43,
        title: "HP DeskJet Ink 2139",
    },
    {
        id: 44,
        title: "HP DeskJet Ink 2511",
    },
    {
        id: 45,
        title: "HP DeskJet Ink 2512",
    },
    {
        id: 46,
        title: "HP DeskJet Ink 2513",
    },
    {
        id: 47,
        title: "HP DeskJet Ink 2514",
    },
    {
        id: 48,
        title: "HP DeskJet Ink 2515",
    },
    {
        id: 49,
        title: "HP DeskJet Ink 2516",
    },
    {
        id: 50,
        title: "HP DeskJet Ink 2517",
    },
    {
        id: 51,
        title: "HP DeskJet Ink 2518",
    },
    {
        id: 52,
        title: "HP DeskJet Ink 2519",
    },
    {
        id: 53,
        title: "HP DeskJet Ink 2541",
    },
    {
        id: 54,
        title: "HP DeskJet Ink 2542",
    },
    {
        id: 55,
        title: "HP DeskJet Ink 2543",
    },
    {
        id: 56,
        title: "HP DeskJet Ink 2544",
    },
    {
        id: 57,
        title: "HP DeskJet Ink 2545",
    },
    {
        id: 58,
        title: "HP DeskJet Ink 2546",
    },
    {
        id: 59,
        title: "HP DeskJet Ink 2547",
    },
    {
        id: 60,
        title: "HP DeskJet Ink 2548",
    },
    {
        id: 61,
        title: "HP DeskJet Ink 2549",
    },
    {
        id: 62,
        title: "HP DeskJet Ink 3511",
    },
    {
        id: 63,
        title: "HP DeskJet Ink 3512",
    },
    {
        id: 64,
        title: "HP DeskJet Ink 3513",
    },
    {
        id: 65,
        title: "HP DeskJet Ink 3514",
    },
    {
        id: 66,
        title: "HP DeskJet Ink 3515",
    },
    {
        id: 67,
        title: "HP DeskJet Ink 3516",
    },
    {
        id: 68,
        title: "HP DeskJet Ink 3517",
    },
    {
        id: 69,
        title: "HP DeskJet Ink 3518",
    },
    {
        id: 70,
        title: "HP DeskJet Ink 3519",
    },
    {
        id: 71,
        title: "HP DeskJet Ink 3630",
    },
    {
        id: 72,
        title: "HP DeskJet Ink 3631",
    },
    {
        id: 73,
        title: "HP DeskJet Ink 3632",
    },
    {
        id: 74,
        title: "HP DeskJet Ink 3633",
    },
    {
        id: 75,
        title: "HP DeskJet Ink 3634",
    },
    {
        id: 76,
        title: "HP DeskJet Ink 3635",
    },
    {
        id: 77,
        title: "HP DeskJet Ink 3636",
    },
    {
        id: 78,
        title: "HP DeskJet Ink 3637",
    },
    {
        id: 79,
        title: "HP DeskJet Ink 3638",
    },
    {
        id: 80,
        title: "HP DeskJet Ink 3639",
    },
    {
        id: 81,
        title: "HP DeskJet Ink 3700",
    },
    {
        id: 82,
        title: "HP DeskJet Ink 3720",
    },
    {
        id: 83,
        title: "HP DeskJet Ink 3723",
    },
    {
        id: 84,
        title: "HP DeskJet Ink 3755",
    },
    {
        id: 85,
        title: "HP DeskJet Ink 4530",
    },
    {
        id: 86,
        title: "HP DeskJet Ink 5730",
    },
    {
        id: 87,
        title: "HP DeskJet Ink 5731",
    },
    {
        id: 88,
        title: "HP DeskJet Ink 5732",
    },
    {
        id: 89,
        title: "HP DeskJet Ink 5733",
    },
    {
        id: 90,
        title: "HP DeskJet Ink 5734",
    },
    {
        id: 91,
        title: "HP DeskJet Ink 5735",
    },
    {
        id: 92,
        title: "HP DeskJet Ink 5736",
    },
    {
        id: 93,
        title: "HP DeskJet Ink 5737",
    },
    {
        id: 94,
        title: "HP DeskJet Ink 5738",
    },
    {
        id: 95,
        title: "HP DeskJet Ink 5739",
    },
    {
        id: 96,
        title: "HP DeskJet Ink 2050",
    },
    {
        id: 97,
        title: "HP DeskJet Ink 1050",
    },
    {
        id: 98,
        title: "HP DeskJet Ink 3050",
    },
    {
        id: 99,
        title: "HP DeskJet Ink f4480",
    },
    {
        id: 100,
        title: "HP DeskJet Ink 6940",
    },
    {
        id: 101,
        title: "HP DeskJet Ink 3522",
    },
    {
        id: 102,
        title: "HP DeskJet Ink 3050a",
    },
    {
        id: 103,
        title: "HP DeskJet Ink 1020",
    },
    {
        id: 104,
        title: "HP DeskJet Ink 4250",
    },
];
    
  return (
    <div>
                <Helmet>
                <link rel="canonical" href="https://www.getprinterhelp.com/hp-deskjet-ink" />
            </Helmet>
            <h1 className='text-black text-center p-10 bg-blue-500 mt-20 text-2xl font-bold md:text-3xl lg:text-4xl'>HP DeskJet Ink Setup and Installation
</h1>

            <div
                name=""
                className="bg-gradient-to-b from-gray-100 to-white w-full text-white md:h-full text-center md:text-left p-4"
            >
                <div className="max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full">
                    <div className="pb-8 mt-6">
                        
                        <p className="text-black text-3xl font-bold  flex items-center justify-center pb-4 md:text-4xl">
                        Choose your Model
                        </p>
                    </div>

                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 sm:px-5 py-2">
                        {items.map(({title,link }) => (
                            <div to={link} className="mt-8 sm:mt-12 border flex flex-col items-center shadow-lg shadow-gray-600 p-4 bg-white rounded-lg hover:scale-105 duration-500 mx-auto">
                                <img src={HPDeskject} alt="" className="w-[30%] h-[80%]" />
                                <h1 className='text-xl font-bold text-black mt-4 mb-4 text-center'>{title}</h1>
                                <p className="text-gray-600 text-center">Printer Installation and Setup, Troubleshoot Problem</p>

                                <Link to="/knowmore-hp-deskjet-printer" className='mt-4'>
                                    <button className='p-3 bg-slate-800 rounded-md hover:bg-slate-600 duration-200'>Know More</button>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div> 
  )
}

export default HPDeskJet