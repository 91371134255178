import React, { useEffect } from "react";
import HpEnvyPrinter from "../../assests/HpEnvyPrinter.png";

function HpDeskJetInk() {
  useEffect(() => {
    document.title = "HP LaserJet Printer Setup";
  }, []);
  return (
    <>
      <div className="bg-gradient-to-r from-blue-300 to-blue-700 -skew-y-6 ">
        <div className="flex flex-col mx-auto max-w-screen-xl gap-10 items-center justify-center md:flex-row pt-28 p-20">
          <div className="">
            <h1 className="text-2xl font-bold md:text-4xl lg:text-5xl">
              HP LaserJet Printer Setup
            </h1>
          </div>
          <div className="p-4 sm:p-10">
            <img className="" src={HpEnvyPrinter} alt="" />
          </div>
        </div>
      </div>

      {/* 2 section*/}
      <div className="mx-auto max-w-screen-xl mt-16 flex flex-col p-6">
        <h1 className="text-3xl font-semibold  text-center">
          HP Laserjet Printer Wireless Setup & Installation on Windos and MAC
        </h1>
        <p className="text-xl pt-4">
          The present writeup has been penned down keeping in mind the various
          queries we have been receiving in regards to the procedure to connect
          HP Laserjet wireless printer on Windows, Mac, and Mobile phones. So
          without much ado let’s begin the procedural steps!
        </p>
      </div>

      {/* HP Officejet Pro Setup to Wifi network */}
      <div className="mx-auto max-w-screen-xl mt-4 flex flex-col p-6">
        <h1 className="text-3xl font-semibold text-center">
          That’s how you can connect your HP Laserjet wireless printer with
          Wi-Fi router
        </h1>

        <li className="list-disc text-xl pt-6 pl-6">
          Here, as the first step select “Cancel” if you receive the message
          saying “Enter the WPS pin for your printer message and continue with
          the below subsequent steps.
        </li>
        <li className="list-disc pl-6 text-xl">
          Make sure you place the printer near the wifi router.
        </li>
        <li className="list-disc pl-6 text-xl">
          After turning on your printer ensure that the ink cartridges are
          installed and paper is properly loaded in the print tray.
        </li>
        <li className="list-disc pl-6 text-xl">
          Connect the printer to the local network.
        </li>
        <p className="text-xl font-bold pt-6 pb-6">
          For printers with touchscreen control panel:
        </p>
        <li className="list-disc pl-6 text-xl">
          From your printer select “Restore Network Settings” from the wireless
          settings or restore settings menu.
        </li>
        <li className="list-disc pl-6 text-xl">
          Obtain the SSID name and password.
        </li>
        <li className="list-disc pl-6 text-xl">
          Select the “Wireless setup wizard” from the wireless settings or the
          network setup menu. Thereafter select your network name and enter the
          password.
        </li>
        <p className="text-xl font-bold pt-6 pb-6">
          For printers without touchscreen control panel:
        </p>
        <li className="list-disc pl-6 text-xl">
          Press and hold the wireless button for continuous five seconds on your
          printer until the light starts blinking.
        </li>
        <li className="list-disc pl-6 text-xl">
          Now within two minutes press and hold the WPS button on the router
          until the connection process begins. Here once the connection
          establishes, the printer’s light will automatically stop flashing.
        </li>
      </div>

      {/* Connect HP Laserjet wireless printer to Windows. */}
      <div className="mx-auto max-w-screen-xl mt-4 flex flex-col p-6">
        <h1 className="text-3xl font-semibold">
          Connect HP Laserjet wireless printer to Windows.
        </h1>
        <h1 className="text-3xl font-semibold pt-6 text-center">
          Here are the procedural steps to connect HP Laserjet wireless printer
          to Windows computer
        </h1>

        <li className="list-disc text-xl pt-6 pl-6">
          First of all, ensure that the printer is turned ON and is connected
          with Wi-Fi router as already mention above.
        </li>
        <li className="list-disc pl-6 text-xl">
          Now visit HP’s driver downloads page (www.123.hp.com/setup).
          Thereafter, when prompted enter your printer model and ensure that the
          operating system version is correct.
        </li>
        <li className="list-disc text-xl pl-6">
          Click “Printer” on the “Let’s identify your product to get started”
          page, type in your printer model number, and click the “Submit “
          button.
        </li>
        <li className="list-disc text-xl pl-6">
          Here, click “Change” if you wish to change the operating system.
        </li>
        <li className="list-disc text-xl pl-6">
          Now click “Download” under the “Driver” heading for the complete
          software package or the user may select “Basic Drivers” to obtain
          other driver options.
        </li>
        <li className="list-disc text-xl pl-6">
          Here search Windows for devices and then select “Devices and Printers”
          from the control panel.
        </li>

        <img
          className="mx-auto pt-4"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/cwbe3no7axcdzfkyir7p.png"
          alt=""
        />

        <li className="list-disc text-xl pl-6  pt-6">
          Click on the “Add Printer” option on top upper corner in right
          section.
        </li>

        <img
          className="mx-auto pt-4 pb-4"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/wc2isbmkozj7rklwnege.png"
          alt=""
        />

        <li className="list-disc text-xl pl-6 pt-6">
          Select your HP Laserjet printer model number from the display window
        </li>
        <li className="list-disc text-xl pl-6">
          After few seconds your HP Laserjet printer will connect with your
          Windows System
        </li>
        <li className="list-disc text-xl pl-6">
          Congratulations! You are now good to print, fax, or scan on your
          printer according to your will.
        </li>
      </div>

      {/* Connect HP Laserjet Printer on Windows With USB */}
      <div className="mx-auto max-w-screen-xl mt-4 flex flex-col p-6">
        <h1 className="text-3xl font-semibold">
          Connect HP Laserjet Printer on Windows With USB
        </h1>

        <li className="list-disc text-xl pl-6 pt-6">
          To connect your HP Laserjet printer with wired connection, you need to
          have a USB Cable
        </li>
        <li className="list-disc pl-6 text-xl">
          Connect USB from one end with HP Laserjet printer and other end with
          computer{" "}
        </li>
        <li className="list-disc pl-6 text-xl">
          Then, go to HP’s website and download the printer driver as per your
          model. After completion of the setup, follow the instructions given on
          the next step.
        </li>
        <li className="list-disc text-xl pl-6">
          Go to the control panel{" >"} device and printer{" > "}Printer and
          Scanner
        </li>

        <img
          className="mx-auto pt-4 pb-4"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/cwbe3no7axcdzfkyir7p.png"
          alt=""
        />

        <img
          className="mx-auto pt-4 pb-4"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/mqjwsmpfbpfh7m8rfmz2.png"
          alt=""
        />

        <li className="list-disc pl-6 pt-6 text-xl">
          Then click on ‘add new printer’ option. Now you need to select your HP
          printer from the list of shown model numbers.
        </li>
        <li className="list-disc text-xl pl-6">
          Finally, your HP Laserjet printer setup is finished with USB
          connection to your computer.
        </li>
      </div>

      {/* Connecting HP Laserjet wireless printer to MAC*/}
      <div className="mx-auto max-w-screen-xl mt-4 flex flex-col p-6">
        <h1 className="text-3xl font-semibold">
          Connecting HP Laserjet wireless printer to MAC
        </h1>

        <li className="list-disc text-xl pl-6 pt-6">
          As the first step, connect the printer and your computer to the same
          Wi-Fi network using the steps mentioned in the first section of this
          writeup.{" "}
        </li>
        <li className="list-disc text-xl pl-6">
          Now open your browser and enter” hp.com/setup” or “123.hp.com/setup”
          and proceed to the next step.
        </li>
        <li className="list-disc text-xl pl-6">
          Thereafter download the driver of your printers model number and
          complete the setup installation process.{" "}
        </li>
        <li className="list-disc text-xl pl-6">
          Now go to the “Apple'” menu and click on the “System preferences”.
        </li>
        <img
          className="mx-auto pt-4 pb-4"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/vrtaqmvwpf2wbr1tv3te.gif"
          alt=""
        />
        <li className="list-disc text-xl pl-6">
          From there, click on “Printers & Scanners” option.{" "}
        </li>
        <img
          className="mx-auto pt-4 pb-4"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726121115/qege6sucaobi1ufkbo7w.png"
          alt=""
        />
        <li className="list-disc pt-6 pl-6 text-xl">
          Now from the list of printers provided, select the name of the printer
          that you are using and click on the “+” sign to add your printer.
          Thereafter select the name of your printer and hit the “Continue”
          option to proceed to the next step.
        </li>
        <img
          className="mx-auto pt-4 pb-4"
          src="https://res.cloudinary.com/dby6tugob/image/upload/v1726122931/yptooj0pzbmkqzdgztbs.png"
          alt=""
        />
        <li className="list-disc pl-6 text-xl">
          Lastly click on the add option to add your printer and thereafter
          close the window
        </li>
        <p className="text-xl pt-6">
          Your HP Laserjet printer is now successfully connected to your mac
          computer. You can either print or scan as per your requirement.
        </p>
      </div>

      {/* Connecting HP Laserjet wireless printer to the smartphone */}
      <div className="mx-auto max-w-screen-xl mt-4 flex flex-col p-6">
        <h1 className="text-3xl font-semibold">
          Connecting HP Laserjet wireless printer to the smartphone
        </h1>
        <p className="text-xl pt-4">
          Here in order to connect your HP Laserjet wireless printer to the
          smartphone, the user needs to first install the HP print service app.
          So let’s begin with the procedural steps.
        </p>

        <li className="list-disc text-xl pl-6 pt-6">
          Firstly ensure that your Android device is connected to the same
          network as the printer and the Wi-Fi direct is enabled on your printer
          and Android device.
        </li>
        <li className="list-disc pl-6 text-xl">
          Now, install the HP Print service driver app on your phone.
        </li>
        <li className="list-disc pl-6 text-xl">
          Once the installation completes, choose your Android device in order
          to turn on the plugin. For Android 7, the HP print service plugin
          turns on automatically after the installation. However, for Android 6
          and earlier devices tap “Settings” and search for and open “HP Print
          Service” and then tap “On”.
        </li>
        <li className="list-disc text-xl pl-6">
          Here, turn off any other print service plugins if required and you are
          ready to print via your HP printer as shown in the next steps.
        </li>
        <li className="list-disc text-xl pl-6">
          Open the item you wish to print, tap the menu icon displayed by three
          vertical icons, and tap “Print’ after which a print preview screen
          will open.
        </li>
        <li className="list-disc text-xl pl-6">
          Now to view the printer list, tap the down arrow, and select your Hp
          printer model name in order to print over the network.
        </li>
        <li className="list-disc text-xl pl-6">
          Lastly, tap the down arrow to change any printer settings if you wish
          to and tap the print icon and you are good to go!
        </li>

        <p className="text-xl pt-4">
          We hope that this writeup comes to your best advantage while looking
          for the most effective ways to connect your HP Laserjet wireless
          printer.
        </p>
      </div>
    </>
  );
}

export default HpDeskJetInk;
