import React from "react";
import { Link } from "react-router-dom";
import NewPrinterSetupImage from "../../assests/CardImages/New Printer Setup.png";
import PrinterTroubleshootingImage from "../../assests/CardImages/Printer Troubleshooting.png";
import PrinterDriverInstallationImage from "../../assests/CardImages/Printer Driver Installation.png";
import ConnectPrinterToWifiNetworkImage from "../../assests/CardImages/Connect Printer To Wifi Network.png";
import WirelessPrinterSetupImage from "../../assests/CardImages/Wireless Printer Setup.jpg";
import PrinterOfflineImage from "../../assests/CardImages/HP Printer Offline.gif";
import DiagnosePrinterProblemImage from "../../assests/CardImages/Diagnose Printer Problem.png";
import PrinterColorPrintingErrorImage from "../../assests/CardImages/Printer Color Printing Error.png";

export default function Cards() {
  const items = [
    {
      id: 1,
      text: "New Printer Setup",
      link: "/new-printer-setup",
      src: NewPrinterSetupImage,
    },
    {
      id: 2,
      link: "/hp-printer-troubleshooting-guide",
      src: PrinterTroubleshootingImage,
      text: "Printer Troubleshooting",
    },
    {
      id: 3,
      link: "/how-to-install-hp-printer-driver",
      src: PrinterDriverInstallationImage,
      text: "Printer Driver Installation",
    },
    {
      id: 4,
      link: "/connect-canon-printer-to-wifi",
      src: ConnectPrinterToWifiNetworkImage,
      text: "Connect Printer To Wifi Network",
    },
    {
      id: 5,
      link: "/setup-hp-printer",
      src: WirelessPrinterSetupImage,
      text: "Wireless Printer Setup",
    },
    {
      id: 6,
      link: "/hp-printer-is-offline",
      src: PrinterOfflineImage,
      text: "HP Printer Offline",
    },
    {
      id: 7,
      link: "/guide-to-diagnose-hp-printer-problems",
      src: DiagnosePrinterProblemImage,
      text: "Diagnose Printer Problem",
    },
    {
      id: 8,
      link: "/hp-printer-color-not-printing",
      src: PrinterColorPrintingErrorImage,
      text: "Printer Color Printing Error",
    },
  ];
  return (
    <>
      <div
        name=""
        className="bg-gradient-to-b from-gray-100 to-white w-full text-white md:h-full text-center md:text-left p-4"
      >
        <div className="max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full">
          <div className="pb-8">
            <p className="text-black text-3xl font-bold  flex items-center justify-center pb-4 md:text-4xl">
              What Problem Are You Facing Today With Your Printer?
            </p>
          </div>

          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-8 sm:px-5 py-2">
            {items.map(({ id, src, text, link }) => (
              <Link
                key={id}
                to={link}
                className="mt-8 sm:mt-12 border flex flex-col items-center shadow-lg shadow-gray-600 p-4 bg-white rounded-lg hover:scale-105 duration-500"
              >
                <img src={src} alt="" loading="lazy" className="w-[30%] h-[80%]" />
                <p className="text-black hover:text-blue-600 duration-200">
                  {text}
                </p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
