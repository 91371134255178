import React from 'react'
import DoctorToolImage from './../../assests/Doctor-tool.gif'

function DoctorTool() {
    return (
        <section className="pt-20 md:pt-40">
        <div className="container mx-auto px-8 lg:flex gap-4">
        <div className="lg:w-1/2 ">
            <img loading="lazy" src={DoctorToolImage} alt="" className=' lg:ml-28 lg:w-3/5'/> 
        </div>
    
            <div className="text-start lg:text-left lg:w-1/2">
                <h1 className="text-2xl xl:text-3xl font-bold border-b-4 border-blue-700">To proceed with printer setup, follow these steps:</h1>
                <p className="text-lg mt-6 font-light">Setting up a printer for the first time can indeed be daunting. Here's a simplified step-by-step guide to help streamline the process.</p>
    
                <ol className="mt-4 text-gray-600">
            <li>1. Unbox Carefully: Remove the printer from its packaging, ensuring not to damage any components.</li>
            <li>2. Power Connection: Plug in the power cord to the printer and connect it to a power outlet. Turn on the printer.</li>
            <li>3. Paper Loading: Open the paper tray and adjust the guides to accommodate the paper size you're using. Load the paper into the tray, ensuring it's aligned properly.</li>
            <li>4. Connect to Computer: Depending on your printer type, connect it to your computer using a USB cable or set it up wirelessly by connecting it to your Wi-Fi network.</li>
            <li>5. Driver Installation: Install the printer drivers and software on your computer. You can usually download these from the printer manufacturer's website.</li>
            <li>6. Next, download and install the printer driver software so that your printer can connect to the computer or smartphone. </li>
            <li>7. Follow Prompts: During installation, follow the on-screen prompts to complete the setup process. This may include selecting your printer model, configuring network settings, etc.</li>
            <li>8. Printer setup,Install printer,Set up printer,Printer software,Driver for printer,Connect printer,Add printer,Printer driver setup.   </li>
        </ol>
            </div>
            
        </div>
    </section>
      )
}

export default DoctorTool